import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/gameList'
import Backbone from 'backbone'
import Swal from 'lib/swal'
import Toastr from 'toastr'
import _ from 'lodash'

import Socket from 'lib/socket'
import FileUtils from 'lib/file'
import Language from 'lib/language'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:gameList')

const GENERIC_UPLOAD_ERROR = 'UPLOAD_ERROR'

const DRAGENTER_EVENT = 'dragenter'
const DRAGLEAVE_EVENT = 'dragleave'

const VISIBLE_CLASS = 'visible'

const MIME_TYPE_ZIP = 'application/zip'
const MIME_TYPE_ZIP_ALT = 'application/x-zip-compressed'

const SUPPORTED_MIME_TYPES = [
	MIME_TYPE_ZIP,
	MIME_TYPE_ZIP_ALT,
]


const GameListView = Marionette.View.extend({
	template: template,
	className: 'gameList container',

	ui: {
		dragOverlay: '#dragOverlay',
		uploadGameDataButton: '#uploadGameDataButton',
		deleteButton: '.deleteButton',
	},

	events: {
		'click @ui.uploadGameDataButton': 'handleGameDataFileInput',
		'click @ui.deleteButton': 'handleDeleteGame',
		'dragleave @ui.dragOverlay': 'handleDragLeave',
		'dragover @ui.dragOverlay': 'stopEventProcessing', // Required to be able to receive drop event.
		'drop @ui.dragOverlay': 'handleDrop',
	},

	initialize() {
		debug('Initializing GameListView:',this.options)
		this.fetchGameList()

		this.model = new Backbone.Model()
		this.bindDragEvents()
	},

	bindDragEvents() {
		const dragHandler = this.handleDragEnter.bind(this)

		document.body.addEventListener(DRAGENTER_EVENT,dragHandler)
		this.on('before:detach',()=> {
			document.body.removeEventListener(DRAGENTER_EVENT,dragHandler)
		})
	},

	async fetchGameList() {
		try {
			this.games = await Socket.emit('games:list')
			debug('Received games:',this.games)

			for (const game of this.games) {
				game.languageFlag = Language.getFlag(game.language)
				game.downloadLink = `services/download/gamedata/${game.id}-${game.language}`
				game.gameId = `${game.id}-${game.language}`
			}

			this.updateGameList(this.games)
		} catch(err) {
			Toastr.error(`Error listing available games: ${err}`)
		}
	},

	async updateGameList() {
		await this.ensureRendered()

		this.model.set('games', this.games)
		this.render()
	},

	async handleGameDataFileInput() {
		try {
			const file = await FileUtils.selectFile('.zip')
			this.uploadFile(file)
		} catch(err) {
			console.error(err)
			Toastr.error(err)
		}
	},

	hasFiles(dragEvent) {
		if (dragEvent.originalEvent)
			dragEvent = dragEvent.originalEvent

		const types = dragEvent.dataTransfer.types
		return !_.isEmpty(types) && types[0] == 'Files'
	},

	getEventFiles(dragEvent) {
		if (dragEvent.originalEvent)
			dragEvent = dragEvent.originalEvent

		return dragEvent.dataTransfer?.files
	},

	stopEventProcessing(event) {
		event.stopPropagation()
		event.preventDefault()
	},

	handleDragEnter(event) {
		debug('drag enter')
		if (this.hasFiles(event)) {
			this.stopEventProcessing(event)
			this.ui.dragOverlay.addClass(VISIBLE_CLASS)
		}
	},

	handleDragLeave(event) {
		debug('drag leave')
		this.stopEventProcessing(event)
		this.ui.dragOverlay.removeClass(VISIBLE_CLASS)
	},

	handleDrop(event) {
		this.handleDragLeave(event)
		debug('Content dropped:',event,event.originalEvent.dataTransfer.files)

		if (!this.hasFiles(event)) {
			debug('Ignoring drop without file')
			return
		}

		const droppedFiles = this.getEventFiles(event)
		debug('Received dropped files:',droppedFiles)
		if (droppedFiles.length > 1) {
			console.error('Multiple files upload not supported.')
			Toastr.error('Multiple files upload not supported.')
			return
		}

		this.uploadFile(droppedFiles[0])
	},

	async uploadFile(file) {
		if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
			console.error('File to upload isn\'t supported:',file.type)
			Toastr.error('File to upload isn\'t supported')
			return
		}

		try {
			await FileUtils.upload('services/upload/gamedata', {file})
			debug('File upload success')
			Toastr.success(`File uploaded successfully: ${file.name}`)

			this.fetchGameList()
		} catch(err) {
			Toastr.error(`Error while uploading ${file.name}: ${err?.message || err || GENERIC_UPLOAD_ERROR}`)
		}
	},

	async handleDeleteGame(event) {
		const currentTarget = event.currentTarget
		const gameId = currentTarget.dataset.gameid
		await Swal.confirm(`Delete game \`${gameId}\`?`, async ()=> {
			try {
				await Socket.emit('game:delete',gameId)
				Toastr.success('Game deleted.')

				this.fetchGameList()
			} catch(err) {
				debug('Error deleting game:',err)
				Toastr.error('Error deleting game.')
			}
		})
	},
})

export default GameListView
