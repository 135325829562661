import Marionette from 'backbone.marionette'
import template from 'templates/admin/userList'
import Toastr from 'toastr'

import Socket from 'lib/socket'

import Debug from 'debug'
const debug = Debug('pandore:views:admin:userList')

const AdminUserListView = Marionette.View.extend({
	template: template,
	className: 'userList container',

	initialize() {
		debug('Initializing AdminUserListView:',this.options)
		this.userList = []
		this.updateList()
	},

	templateContext() {
		return {
			createLink: `#${this.options.currentPath}/new`,
		}
	},

	async updateList() {
		try {
			this.userList = await Socket.emit('user:list')
			debug('Received user list:',this.userList)

			this.userList.forEach((user)=> {
				user.editLink = `#${this.options.currentPath}/${user.id}`
			})

			await this.ensureRendered()
			this.render()
		} catch(err) {
			console.error('Error fetching user list:',err)
			Toastr.error('Error fetching user list.')
		}
	},

	serializeData() {
		return {
			users: this.userList,
		}
	},
})

export default AdminUserListView
