import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/sessionCreate'
import Backbone from 'backbone'
import Toastr from 'toastr'
import _ from 'lodash'
import Path from 'path'
import {sprintf} from 'sprintf-js'
import Moment from 'moment'

import Config from 'etc/config'
import Socket from 'lib/socket'
import Language from 'lib/language'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:sessionCreate')

const SessionCreateView = Marionette.View.extend({
	template: template,
	className: 'sessionCreate container',

	ui: {
		teamNameInput: '#teamNameInput',
		languageSelector: '#languageInput',
		gameSelector: '#gameInput',
	},

	events: {
		'submit form': 'handleFormSubmit',
	},

	modelEvents: {
		'change:language': 'handleLanguageChange',
	},

	bindings: {
		'#teamNameInput': 'team_name',
		'#languageInput': 'language',
		'#gameInput': 'game',
		'#externalIdInput': {
			observe: 'external_id',
			onSet: function(value) {
				value = value?.trim()
				if (value)
					return value
				return null
			},
		},
	},

	initialize() {
		debug('Initializing SessionCreateView:',this.options)
		this.model = new Backbone.Model()
		this.model.set('team_name',this.getDefaultTeamName())
		this.listGames()
	},

	getDefaultTeamName() {
		return sprintf(Config.defaultSessionName, Moment().format('HH:mm'))
	},

	onRender() {
		this.stickit()
		this.ui.languageSelector.trigger('change') // force language value to be set in model.
	},

	onAttach() {
		this.ui.teamNameInput.select()
	},

	async listGames() {
		try {
			this.games = await Socket.emit('games:list')
			debug('Received games:',this.games)
			this.updateGameList(this.games)
		} catch(err) {
			Toastr.error(`Error listing available games: ${err}`)
		}
	},

	async updateGameList() {
		await this.ensureRendered()
		const languages = Language.list(_.map(this.games, 'language'))
		for (const lang of languages)
			this.ui.languageSelector.append(`<option value="${lang.id}">${lang.name}</option>`)

		this.model.set('language',languages[0].id) // must set default value manually.
	},

	handleLanguageChange(model,value) {
		const languageGames = this.games.filter((game)=> {
			return game.language == value
		})

		this.ui.gameSelector.empty()
		for (const game of languageGames)
			this.ui.gameSelector.append(`<option value="${game.id}">${game.name}</option>`)

		this.model.set('game',languageGames[0].id) // must set default value manually.
	},

	async handleFormSubmit(event) {
		event.preventDefault()
		event.stopPropagation()

		const data = this.model.toJSON()

		debug('Creating session with data:',data)

		try {
			await Socket.emit('session:create',data)
			Toastr.success('Session created.')
			this.trigger('navigate',Path.dirname(this.options.currentPath))
		} catch(err) {
			Toastr.error(`Error creating session: ${err}`)
		}
	},
})

export default SessionCreateView
