{
  "name": "escape_server",
  "displayName": "Escape Server",
  "version": "3.18.2",
  "author": "coqsenpate",
  "license": "UNLICENSED",
  "repository": {
    "type": "git",
    "url": "git@bitbucket.org:coqsenpate/pandore-escape_server.git"
  },
  "scripts": {
    "start": "node server",
    "preinstall": "check-node-version --node \"$(< .nvmrc)\""
  },
  "devDependencies": {
    "backbone": "^1.6.0",
    "backbone.marionette": "^4.1.3",
    "backbone.stickit": "^0.9.2",
    "bootstrap": "^4.6.2",
    "connect-livereload": "^0.6.1",
    "esbuild": "^0.24.0",
    "esbuild-plugin-copy": "^2.1.1",
    "esbuild-plugin-handlebars": "^1.0.3",
    "esbuild-sass-plugin": "^3.3.1",
    "fontawesome-pro": "github:happinov/fontawesome-pro-6.6.0",
    "grunt": "^1.6.1",
    "grunt-contrib-clean": "^2.0.1",
    "grunt-contrib-copy": "^1.0.0",
    "grunt-contrib-symlink": "^1.0.0",
    "grunt-contrib-watch": "^1.1.0",
    "grunt-esbuild": "^1.0.1",
    "grunt-express-server": "^0.5.4",
    "grunt-newer": "^1.3.0",
    "grunt-open": "^0.2.4",
    "grunt-replace": "^2.0.2",
    "handlebars": "^4.7.8",
    "jquery": "^3.7.1",
    "js-datepicker": "^5.18.3",
    "load-grunt-tasks": "^5.1.0",
    "path-browserify": "^1.0.1",
    "popper.js": "^1.16.1",
    "socket.io-client": "^4.8.1",
    "sprintf-js": "^1.1.3",
    "sweetalert2": "^11.14.4",
    "time-grunt": "^2.0.0",
    "toastr": "^2.1.4"
  },
  "dependencies": {
    "@somesmall.studio/rtpmidi": "github:coqsenpate/node-rtpmidi#custom",
    "ajv": "^8.17.1",
    "axios": "^1.7.7",
    "bcrypt": "^5.1.1",
    "busboy": "^1.6.0",
    "compression": "^1.7.4",
    "cors": "^2.8.5",
    "cron": "^3.1.7",
    "debug": "^4.3.7",
    "express": "^4.21.1",
    "express-session": "^1.18.1",
    "extract-zip": "^2.0.1",
    "lodash": "^4.17.21",
    "log4js": "^6.9.1",
    "mdns": "^2.7.2",
    "moment": "^2.30.1",
    "mv": "^2.1.1",
    "mysql": "^2.18.1",
    "project-name-generator": "^2.1.9",
    "semver": "^7.6.3",
    "socket.io": "^4.8.1",
    "zip-stream": "^7.0.2"
  }
}
