
import RealSwal from 'sweetalert2'

import Debug from 'debug'
const debug = Debug('pandore:lib:swal')

async function prompt(options,callback) {
	const result = await RealSwal.fire(options)
	if (result.isConfirmed)
		callback(result.value)
	// don't do anything if cancelled.
}

function parseIntValue(value,callback) {
	let parsedValue = parseInt(value,10)
	if (Number.isNaN(value)) parsedValue = null
	callback(parsedValue)
}

const Swal = {
	close() {
		RealSwal.close()
	},

	fire(...args) {
		return RealSwal.fire(...args)
	},

	prompt(message,title,defaultValue,type,callback) {
		const options = {
			title: title,
			text: message,
			input: type,
			showCancelButton: true,
			inputValue: defaultValue ?? '',
		}

		prompt(options,callback)
	},

	promptText(message,title,defaultValue,callback) {
		Swal.prompt(message,title,defaultValue,'text',callback)
	},

	promptEmail(message,title,defaultValue,callback) {
		Swal.prompt(message,title,defaultValue,'email',callback)
	},

	promptInt(message,title,defaultValue,callback) {
		Swal.prompt(message,title,defaultValue,'number',(value)=> parseIntValue(value,callback))
	},

	promptSelect(message,title,inputOptions,defaultValue,callback) {
		const options = {
			title: title,
			text: message,
			input: 'select',
			inputOptions: inputOptions,
			showCancelButton: true,
			inputValue: defaultValue ?? '',
		}

		prompt(options,callback)
	},

	promptPosInt(message,title,defaultValue,callback) {
		const options = {
			title: title,
			text: message,
			showCancelButton: true,
			inputValue: defaultValue,
			input: 'number',
			inputAttributes: {
				min: 1,
			},
		}

		prompt(options,(value)=> parseIntValue(value,callback))
	},

	async confirm(message,callback) {
		const options = {
			title: 'Confirm',
			text: message,
			showCancelButton: true,
		}

		const result = await RealSwal.fire(options)
		if (result.isConfirmed)
			callback()
	},
}

export default Swal
