// check list on https://www.theiphonewiki.com/wiki/Models

const MAPPING = {
	'apple': {
		'__SOURCE': 'check list on https://www.theiphonewiki.com/wiki/Models or https://everymac.com/',
		'iPad1,1': 'iPad',
		'iPad2,1': 'iPad 2 WiFi',
		'iPad2,2': 'iPad 2 Cellular',
		'iPad2,3': 'iPad 2 Cellular',
		'iPad2,4': 'iPad 2 WiFi',
		'iPad2,5': 'iPad mini WiFi',
		'iPad2,6': 'iPad mini Cellular',
		'iPad2,7': 'iPad mini Cellular',
		'iPad3,1': 'iPad 3 WiFi',
		'iPad3,2': 'iPad 3 Cellular',
		'iPad3,3': 'iPad 3 Cellular',
		'iPad3,4': 'iPad 4 WiFi',
		'iPad3,5': 'iPad 4 Cellular',
		'iPad3,6': 'iPad 4 Cellular',
		'iPad4,1': 'iPad Air WiFi',
		'iPad4,2': 'iPad Air Cellular',
		'iPad4,3': 'iPad Air Cellular CN',
		'iPad4,4': 'iPad mini 2 WiFi',
		'iPad4,5': 'iPad mini 2 Cellular',
		'iPad4,6': 'iPad mini 2 Cellular CN',
		'iPad4,7': 'iPad mini 3 WiFi',
		'iPad4,8': 'iPad mini 3 Cellular',
		'iPad4,9': 'iPad mini 3 Cellular CN',
		'iPad5,1': 'iPad mini 4 WiFi',
		'iPad5,2': 'iPad mini 4 Cellular',
		'iPad5,3': 'iPad Air 2 WiFi',
		'iPad5,4': 'iPad Air 2 Cellular',
		'iPad6,3': 'iPad Pro 9.7" WiFi',
		'iPad6,4': 'iPad Pro 9.7" Cellular',
		'iPad6,7': 'iPad Pro 12.9" WiFi',
		'iPad6,8': 'iPad Pro 12.9" Cellular',
		'iPad6,11': 'iPad 5 WiFi',
		'iPad6,12': 'iPad 5 Cellular',
		'iPad7,1': 'iPad Pro 12.9" (2nd gen) WiFi',
		'iPad7,2': 'iPad Pro 12.9" (2nd gen) Cellular',
		'iPad7,3': 'iPad Pro 10.5" WiFi',
		'iPad7,4': 'iPad Pro 10.5" Cellular',
		'iPad7,5': 'iPad 6 WiFi',
		'iPad7,6': 'iPad 6 Cellular',
		'iPad7,11': 'iPad 7 WiFi',
		'iPad7,12': 'iPad 7 Cellular',
		'iPad8,1': 'iPad Pro 11" WiFi',
		'iPad8,2': 'iPad Pro 11" WiFi',
		'iPad8,3': 'iPad Pro 11" Cellular',
		'iPad8,4': 'iPad Pro 11" Cellular',
		'iPad8,5': 'iPad Pro 12.9" (3rd gen) WiFi',
		'iPad8,6': 'iPad Pro 12.9" (3rd gen) WiFi',
		'iPad8,7': 'iPad Pro 12.9" (3rd gen) Cellular',
		'iPad8,8': 'iPad Pro 12.9" (3rd gen) Cellular',
		'iPad11,1': 'iPad Mini 5 WiFi',
		'iPad11,2': 'iPad Mini 5 Cellular',
		'iPad11,3': 'iPad Air 3 WiFi',
		'iPad11,4': 'iPad Air 3 Cellular',
		'iPad11,6': 'iPad 8 Wifi',
		'iPad11,7': 'iPad 8 Cellular',
		'iPad12,1': 'iPad 9 Wifi',
		'iPad12,2': 'iPad 9 Cellular',
		'iPad13,1': 'iPad Air 4 Wifi',
		'iPad13,2': 'iPad Air 4 Cellular',
		'iPad13,4': 'iPad Pro 11" (3rd gen) WiFi',
		'iPad13,5': 'iPad Pro 11" (3rd gen) WiFi',
		'iPad13,6': 'iPad Pro 11" (3rd gen) Cellular',
		'iPad13,7': 'iPad Pro 11" (3rd gen) Cellular',
		'iPad13,8': 'iPad Pro 12.9" (5th gen) WiFi',
		'iPad13,9': 'iPad Pro 12.9" (5th gen) WiFi',
		'iPad13,10': 'iPad Pro 12.9" (5th gen) Cellular',
		'iPad13,11': 'iPad Pro 12.9" (5th gen) Cellular',
		'iPad13,16': 'iPad Air 5 Wifi',
		'iPad13,17': 'iPad Air 5 Cellular',
		'iPad13,18': 'iPad (10th gen) WiFi',
		'iPad13,19': 'iPad (10th gen) Cellular',
		'iPad14,1': 'iPad Mini 6 Wifi',
		'iPad14,2': 'iPad Mini 6 Cellular',
		'iPad14,3': 'iPad Pro 11" (4th gen) WiFi',
		'iPad14,4': 'iPad Pro 11" (4th gen) Cellular',
		'iPad14,5': 'iPad Pro 12.9" (6th gen) WiFi',
		'iPad14,6': 'iPad Pro 12.9" (6th gen) Cellular',
		'iPad14,8': 'iPad Air M2 11" WiFi',
		'iPad14,9': 'iPad Air M2 11" Cellular',
		'iPad14,10': 'iPad Air M2 13" WiFi',
		'iPad14,11': 'iPad Air M2 13" Cellular',
		'iPad16,3': 'iPad Pro M4 11" WiFi',
		'iPad16,4': 'iPad Pro M4 11" Cellular',
		'iPad16,5': 'iPad Pro M4 13" WiFi',
		'iPad16,6': 'iPad Pro M4 13" Cellular',

		'iPod1,1': 'iPod touch',
		'iPod2,1': 'iPod touch 2',
		'iPod3,1': 'iPod touch 3',
		'iPod4,1': 'iPod touch 4',
		'iPod5,1': 'iPod touch 5',
		'iPod7,1': 'iPod touch 6',
		'iPod9,1': 'iPod touch 7',

		'iPhone1,1': 'iPhone',
		'iPhone1,2': 'iPhone 3G',
		'iPhone2,1': 'iPhone 3GS',
		'iPhone3,1': 'iPhone 4',
		'iPhone3,2': 'iPhone 4',
		'iPhone3,3': 'iPhone 4 CDMA',
		'iPhone4,1': 'iPhone 4S',
		'iPhone5,1': 'iPhone 5',
		'iPhone5,2': 'iPhone 5 CDMA',
		'iPhone5,3': 'iPhone 5c',
		'iPhone5,4': 'iPhone 5c',
		'iPhone6,1': 'iPhone 5s',
		'iPhone6,2': 'iPhone 5s',
		'iPhone7,1': 'iPhone 6 Plus',
		'iPhone7,2': 'iPhone 6',
		'iPhone8,1': 'iPhone 6s',
		'iPhone8,2': 'iPhone 6s Plus',
		'iPhone8,4': 'iPhone SE',
		'iPhone9,1': 'iPhone 7',
		'iPhone9,2': 'iPhone 7 Plus',
		'iPhone9,3': 'iPhone 7',
		'iPhone9,4': 'iPhone 7 Plus',
		'iPhone10,1': 'iPhone 8',
		'iPhone10,2': 'iPhone 8 Plus',
		'iPhone10,3': 'iPhone X',
		'iPhone10,4': 'iPhone 8',
		'iPhone10,5': 'iPhone 8 Plus',
		'iPhone10,6': 'iPhone X',
		'iPhone11,2': 'iPhone XS',
		'iPhone11,6': 'iPhone XS Max',
		'iPhone11,8': 'iPhone XR',
		'iPhone12,1': 'iPhone 11',
		'iPhone12,3': 'iPhone 11 Pro',
		'iPhone12,5': 'iPhone 11 Pro Max',
		'iPhone12,8': 'iPhone SE 2',
		'iPhone13,1': 'iPhone 12 mini',
		'iPhone13,2': 'iPhone 12',
		'iPhone13,3': 'iPhone 12 Pro',
		'iPhone13,4': 'iPhone 12 Pro Max',
		'iPhone14,2': 'iPhone 13 Pro',
		'iPhone14,3': 'iPhone 13 Pro Max',
		'iPhone14,4': 'iPhone 13 mini',
		'iPhone14,5': 'iPhone 13',
		'iPhone14,6': 'iPhone SE 3',
		'iPhone14,7': 'iPhone 14',
		'iPhone14,8': 'iPhone 14 Plus',
		'iPhone15,2': 'iPhone 14 Pro',
		'iPhone15,3': 'iPhone 14 Pro Max',
		'iPhone15,4': 'iPhone 15',
		'iPhone15,5': 'iPhone 15 Plus',
		'iPhone16,1': 'iPhone 15 Pro',
		'iPhone16,2': 'iPhone 15 Pro Max',
	},
}

export default {
	lookup(manufacturer,modelCode) {
		return (MAPPING[(manufacturer ?? '').toLowerCase()] ?? {})[modelCode] ?? modelCode
	},
}
