import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<tr class=\"vcenter\">\n		<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</td>\n		<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n		<td class=\"center\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_gamemaster") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":20,"column":21},"end":{"line":20,"column":59}}})) != null ? stack1 : "")
    + "</td>\n		<td class=\"center\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":21,"column":21},"end":{"line":21,"column":54}}})) != null ? stack1 : "")
    + "</td>\n		<td><a class=\"btn btn-primary btn-sm\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"editLink") : depth0), depth0))
    + "\"><i class=\"far fa-edit\"></i> Edit</a></td>\n	</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "✅";
},"4":function(container,depth0,helpers,partials,data) {
    return "-";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Users\n	<a class=\"btn btn-primary\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"createLink") : depth0), depth0))
    + "\"><i class=\"far fa-plus-square\"></i> Create</a>\n</h1>\n\n<table class=\"table table-striped\">\n<thead>\n	<tr>\n		<th>Login</th>\n		<th>Name</th>\n		<th class=\"center\">GM</th>\n		<th class=\"center\">Admin</th>\n		<th>Actions</th>\n	</tr>\n</thead>\n<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"users") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "</tbody>\n</table>\n";
},"useData":true});