import Marionette from 'backbone.marionette'
import template from 'templates/admin/externalModules'

import Socket from 'lib/socket'
import Toastr from 'toastr'

import Debug from 'debug'
const debug = Debug('pandore:views:admin:externalModules')

const ExternalModuleView = Marionette.View.extend({
	template: template,
	className: 'externalModules container',

	ui: {
		resetAllButton: '#resetall',
		rebootAllButton: '#rebootall',
	},

	events: {
		'longpress @ui.resetAllButton': 'resetAllModules',
		'longpress @ui.rebootAllButton': 'rebootAllModules',
	},

	initialize() {
		debug('Initializing ExternalModuleView:',this.options)
	},

	onRender() {
		this.ui.resetAllButton.enableLongpress()
		this.ui.rebootAllButton.enableLongpress()
	},

	async resetAllModules() {
		try {
			await Socket.emit('external:modules:reset:all')
			Toastr.success('Modules resetted.')
		} catch(err) {
			console.error('Error resetting modules:',err)
			Toastr.error('Error resetting modules.')
		}
	},

	async rebootAllModules() {
		try {
			await Socket.emit('external:modules:reboot:all')
			Toastr.success('Modules rebooted.')
		} catch(err) {
			console.error('Error rebooting modules:',err)
			Toastr.error('Error rebooting modules.')
		}
	},
})

export default ExternalModuleView
