import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/deviceList'
import Toastr from 'toastr'
import _ from 'lodash'

import Moment from 'moment'

import Socket from 'lib/socket'
import SocketUtils from 'lib/socket/utils'
import DeviceName from 'lib/deviceName'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:deviceList')

const CONNECTED_CLASS = 'connected'
const DELETED_CLASS = 'deleted'

function getTargetUuid(event) {
	return event.currentTarget.dataset.uuid
}

const DeviceListView = Marionette.View.extend({
	template: template,
	className: 'deviceList container',

	ui: {
		devices: '.device',
		resetButton: '.resetButton',
	},

	events: {
		'click .renameButton': 'handleRename',
		'click .deleteButton': 'handleDelete',
		'click .findButton': 'handleFind',
		'longpress .resetButton': 'handleReset',
	},

	async initialize() {
		debug('Initializing DeviceListView:',this.options)

		await SocketUtils.registerDeviceSubscriptions(this,this.updateDeviceList,this.updateDisplayedStates,(err)=> {
			console.error(err)
			Toastr.error(`Error listing devices: ${err}`)
		})
	},

	serializeData() {
		return {
			devices: _.isEmpty(this.devices)?null:this.devices,
		}
	},

	onRender() {
		this.ui.resetButton.enableLongpress(2)
	},

	parseResult(list) {
		list ??= []

		for (const item of list) {
			item.sortName = item.name.toLowerCase()
			item.modelName = DeviceName.lookup(item.manufacturer,item.model)
			item.messageLink = `\#${this.options.currentPath}/${item.uuid}`
		}

		return _.sortBy(list,'sortName')
	},

	updateDeviceList(list) {
		debug('Received device list:',list)
		this.devices = this.parseResult(list)
		this.render()
	},

	async updateDisplayedStates(connectedUuids) {
		debug('Updating device states:',connectedUuids)
		await this.ensureRendered()

		this.ui.devices.each((index,el)=> {
			if (connectedUuids.includes(el.dataset.uuid)) {
				el.classList.add(CONNECTED_CLASS)
				el.classList.remove(DELETED_CLASS)
			} else {
				el.classList.remove(CONNECTED_CLASS)
			}
		})
	},

	async handleRename(event) {
		const currentTarget = event.currentTarget
		const currentName = currentTarget.dataset.name

		const newName = prompt('Please type in the new device name.',currentName)?.trim()

		if (!newName || newName == currentName)
			return


		try {
			await Socket.emit('device:rename',getTargetUuid(event),newName)
			Toastr.success('Device renamed.')
		} catch(err) {
			Toastr.error(`Error renaming device: ${err}`)
		}

		// don't refresh, subscription will do it for us.
	},

	async handleDelete(event) {
		if (!confirm(`Do you want to delete \`${event.currentTarget.dataset.name}\` ?`))
			return

		try {
			await Socket.emit('device:delete',getTargetUuid(event))
			Toastr.success('Device marked as deleted.')
		} catch(err) {
			Toastr.error(`Error deleting device: ${err}`)
		}

		// don't refresh, subscription will do it for us.
	},

	async handleFind(event) {
		try {
			await Socket.emit('device:message:send',getTargetUuid(event),'find')
		} catch(err) {
			Toastr.error(`Error trying to find device: ${err}`)
		}
	},

	async handleReset(event) {
		try {
			await Socket.emit('device:reset',getTargetUuid(event))
		} catch(err) {
			Toastr.error(`Error trying to reset device: ${err}`)
		}
	},
})

export default DeviceListView
