
import _ from 'lodash'

import Config from 'etc/config'

const GameLanguages = Config.languages.available
const LanguageMap = {}

GameLanguages.forEach((lang)=> {
	LanguageMap[lang.id] = lang
})

const KnownLanguages = _.map(GameLanguages,'id')

export default {
	getFlag(languageCode) {
		return LanguageMap[languageCode]?.flag ?? `[${languageCode}]`
	},

	list(langList) {
		if (!langList)
			return GameLanguages

		const result = GameLanguages.filter((lang)=> langList.includes(lang.id))
		const unknownLangs = langList.filter((lang)=> !KnownLanguages.includes(lang))

		for (const lang of unknownLangs)
			result.push({
				id: lang,
				name: lang,
			})

		return result
	},

	getDefault() {
		return Config.languages.default
	},
}
