import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-check\">\n	<input class=\"form-check-input\" type=\"checkbox\" id=\"device-item-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\">\n	<label class=\"form-check-label\" for=\"device-item-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\">\n		<i class=\"fas fa-plug connectionIndicator\"></i>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"deviceTypeIcon"),depth0,{"name":"deviceTypeIcon","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n		<span class=\"model\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modelName") : depth0), depth0))
    + "</span>\n	</label>\n</div>\n";
},"usePartial":true,"useData":true});