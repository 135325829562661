
import 'bootstrap'

import Metadata from 'metadata'
document.title = Metadata.name

import Toastr from 'toastr'
// Toastr.options.progressBar = true
Toastr.options.positionClass = 'toast-bottom-left'
Toastr.options.newestOnTop = false

import Marionette from 'backbone.marionette'
import 'backbone.stickit' // need to initialize this.

function ensureRendered() {
	return new Promise ((resolve)=> {
		if (this.isRendered())
			resolve()
		else
			this.once('render', resolve)
	})
}

Marionette.View.prototype.ensureRendered = ensureRendered
Marionette.CollectionView.prototype.ensureRendered = ensureRendered

import JQueryUtils from 'lib/jquery'
JQueryUtils.makeLongpressAvailable()
