import Moment from 'moment'
import _ from 'lodash'

const DateUtils = {
	format(date,format) {
		if (_.isString(date) && !format) {
			// we're formatting current date.
			format = date
			date = null
		}

		date ??= new Date()
		return Moment(date).format(format)
	},

	formatDateTime(date) {
		return DateUtils.format(date,'YYYY-MM-DD HH:mm:ss')
	},

	formatDate(date) {
		return DateUtils.format(date,'YYYY-MM-DD')
	},

	formatTime(date) {
		return DateUtils.format(date,'HH:mm:ss')
	},
}

export default DateUtils
