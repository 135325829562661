import Marionette from 'backbone.marionette'
import template from 'templates/admin/userEditor'
import Backbone from 'backbone'
import Toastr from 'toastr'
import _ from 'lodash'

import Socket from 'lib/socket'

import Debug from 'debug'
const debug = Debug('pandore:views:admin:userEditor')

const NEW_ID = 'new'

const AdminUserEditorView = Marionette.View.extend({
	template: template,
	className: 'userEditor container',

	events: {
		// 'click .submitButton': 'handleSubmit',
		'click .resetButton': 'handleReset',
		'submit form': 'handleSubmit',
	},

	bindings: {
		'#idLabel': {
			observe: 'id',
			onGet: (value)=> {
				if (value)
					return `#${value}`

				return '#new'
			}
		},
		'#usernameInput': 'username',
		'#nameInput': 'name',
		'#passwordInput': 'password',
		'#password2Input': 'password2',
		'#gmCheckbox': 'is_gamemaster',
		'#adminCheckbox': 'is_admin',
	},

	initialize() {
		debug('Initializing AdminUserEditorView:',this.options)
		this.model = new Backbone.Model()

		this.userId = _.head(this.options.remainingPath) || NEW_ID

		if (this.userId == NEW_ID) {
			debug('Creating new user.')
			this.isNewUser = true
		} else {
			this.loadUserData(this.userId)
		}
	},

	async loadUserData(userId) {
		try {
			const userDetails = await Socket.emit('user:details',userId)
			debug('Received user details:',userDetails)
			this.model.set(userDetails)
		} catch(err) {
			if (err == 'NOT_FOUND')
				err = 'User not found.'
			Toastr.error(err)
		}
	},

	onRender() {
		this.stickit()
	},

	getPassword() {
		return this.model.get('password')
	},

	getPassword2() {
		return this.model.get('password2')
	},

	hasPassword() {
		return !!this.getPassword()
	},

	checkPasswordsMatch() {
		return this.hasPassword() && (this.getPassword() == this.getPassword2())
	},

	async handleSubmit(event) {
		event.preventDefault()
		event.stopPropagation()
		debug('Form submitted.')

		let error = null

		if (this.isNewUser && !this.hasPassword())
			error = 'New user must have a password.'
		else if (this.hasPassword() && !this.checkPasswordsMatch())
			error = 'Passwords don\'t match.'

		if (error) {
			Toastr.error(error)
		} else {
			try {
				const userData = this.model.toJSON()

				if (!userData.password) // could be an empty string if ever edited.
					delete userData.password
				delete userData.password2 // this one is never sent.

				debug('User data:',userData)

				if (this.isNewUser) {
					await Socket.emit('user:create',userData)
					Toastr.success('User created.')
				} else {
					await Socket.emit('user:update',userData)
					Toastr.success('User updated.')
				}

				this.trigger('navigate',this.options.currentPath) // go back to list after success.
			} catch(err) {
				Toastr.error(err)
			}
		}
	},

	handleReset() {
		this.model.clear()
		if (!this.isNewUser)
			this.loadUserData(this.userId)
	},
})

export default AdminUserEditorView
