import * as Handlebars from 'handlebars/runtime';
import formatDate from 'templates/helpers/formatDate';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({formatDate});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-striped\">\n	<thead>\n		<tr>\n			<th></th>\n			<th>Name</th>\n			<th class=\"center\">Model</th>\n			<th class=\"center\">OS Version</th>\n			<th class=\"center\">App Version</th>\n			<th class=\"center\">Last Visit</th>\n			<th class=\"center\">Sessions</th>\n			<th>Actions</th>\n		</tr>\n	</thead>\n	<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":1},"end":{"line":39,"column":10}}})) != null ? stack1 : "")
    + "	</tbody>\n</table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<tr class=\"device vcenter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":57}}})) != null ? stack1 : "")
    + "\" data-uuid=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\">\n			<td class=\"center deviceIcons\">\n				<i class=\"fas fa-plug connectionIndicator\"></i>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"deviceTypeIcon"),depth0,{"name":"deviceTypeIcon","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</td>\n			<td>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n			<td class=\"center\" title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"model") : depth0), depth0))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"modelName") : depth0), depth0))
    + "</td>\n			<td class=\"center osVersion\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"version") : depth0), depth0))
    + "</td>\n			<td class=\"center appVersion\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"app_version") : depth0), depth0))
    + "</td>\n			<td class=\"center\">"
    + alias3(lookupProperty(helpers,"formatDate").call(alias1,(depth0 != null ? lookupProperty(depth0,"last_visit") : depth0),"YYYY-MM-DD HH:mm",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":66}}}))
    + "</td>\n			<td class=\"center\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"session_count") : depth0), depth0))
    + "</td>\n			<td class=\"novpadding actions\">\n				<a class=\"btn btn-primary btn-sm messageButton\" title=\"Messages\" href=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"messageLink") : depth0), depth0))
    + "\"><i class=\"far fa-comments\"></i></a>\n				<button class=\"btn btn-primary btn-sm resetButton\" data-uuid=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\"><i class=\"far fa-mug-hot\"></i></button>\n				<button class=\"btn btn-primary btn-sm findButton\" title=\"Find\" data-uuid=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\"><i class=\"far fa-map-marker-question\"></i></button>\n				<button class=\"btn btn-primary btn-sm renameButton\" data-uuid=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\" data-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" title=\"Rename\"><i class=\"far fa-edit\"></i></button>\n				<button class=\"btn btn-danger btn-sm deleteButton\" data-uuid=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\" data-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" title=\"Remove\"><i class=\"far fa-trash-alt\"></i></button>\n			</td>\n		</tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "deleted";
},"5":function(container,depth0,helpers,partials,data) {
    return "<div class=\"message\">No device found.</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Devices</h1>\n\n<div class=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":44,"column":7}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});