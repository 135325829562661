
import Socket from 'lib/socket'

import Debug from 'debug'
const debug = Debug('pandore:lib:socket:utils')

const DEVICES_LIST_SUBSCRIPTION = 'device:list'
const DEVICES_STATE_SUBSCRIPTION = 'device:state'

const GAME_EVENT_SUBSCRIPTION = 'game:event'

const SESSION_DEVICE_LIST_EVENT = 'session:device:list'

const CONNECT_EVENT = 'connect'

const subscriptionCounts = {}

/*****
* NOTE: server side subscription doent track how many
* times subscriptions are registered so we need to do it on this side.
* Multiple similar subscriptions can happen when a view is created
* and the current one hasn't been destroyed yet.
****/

function incrementSubCount(name) {
	if (subscriptionCounts[name] != null)
		subscriptionCounts[name]++
	else
		subscriptionCounts[name] = 1
}

function decrementSubCount(name) {
	if (!isSubscribed(name))
		throw new Error(`Negative subscription count reached for: ${name}`)

	subscriptionCounts[name]--
}

function isSubscribed(name) {
	return subscriptionCounts[name] > 0
}

function reloadPage() {
	debug('Reloading current page.')
	window.location.reload()
}

const SocketUtils = {
	async subscribe(subscriptionType,callback) {
		try {
			if (!isSubscribed(subscriptionType)) {
				debug('Subscribing:',subscriptionType)
				await Socket.emit('subscribe', subscriptionType)
				Socket.on(CONNECT_EVENT,reloadPage) // allows proper resubscription after socket disconnection.
			}

			incrementSubCount(subscriptionType)
			Socket.on(subscriptionType,callback)
			debug('Subscribed to:',subscriptionType)
		} catch(err) {
			console.error('Error subscribing:',subscriptionType,err)
		}
	},

	async unsubscribe(subscriptionType,callback) {
		try {
			Socket.off(subscriptionType,callback)
			decrementSubCount(subscriptionType)
			if (!isSubscribed(subscriptionType)) {
				debug('Unsubscribing:',subscriptionType)
				await Socket.emit('unsubscribe',subscriptionType)
				Socket.off(CONNECT_EVENT,reloadPage)
			}
		} catch(err) {
			console.error('Error unsubscribing:',subscriptionType,err)
		}
	},

	registerViewSubscription(view,eventName,callback,...args) {
		callback = callback.bind(view) // make sure callback context is maintained.
		SocketUtils.subscribe(eventName,callback,...args)
		view.on('detach', ()=> {
			SocketUtils.unsubscribe(eventName,callback)
		})
	},

	async registerDeviceSubscriptions(view,listHandler,stateHandler,errorHandler) {
		listHandler = listHandler.bind(view)
		stateHandler = stateHandler.bind(view)
		errorHandler = errorHandler.bind(view)

		SocketUtils.registerViewSubscription(view,DEVICES_STATE_SUBSCRIPTION,stateHandler)
		SocketUtils.registerViewSubscription(view, DEVICES_LIST_SUBSCRIPTION, async (list)=> {
			listHandler(list)
			try {
				// must refresh states after getting new device list.
				stateHandler(await Socket.emit(DEVICES_STATE_SUBSCRIPTION))
			} catch(err) {
				errorHandler(err)
			}
		})

		try {
			listHandler(await Socket.emit(DEVICES_LIST_SUBSCRIPTION))
			stateHandler(await Socket.emit(DEVICES_STATE_SUBSCRIPTION))
		} catch(err) {
			errorHandler(err)
		}
	},

	async registerSessionDeviceSubscriptions(view,sessionId,listHandler,stateHandler,errorHandler) {
		listHandler = listHandler.bind(view)
		stateHandler = stateHandler.bind(view)
		errorHandler = errorHandler.bind(view)

		const sessionDeviceListEvent = `${SESSION_DEVICE_LIST_EVENT}:${sessionId}`

		SocketUtils.registerViewSubscription(view,DEVICES_STATE_SUBSCRIPTION,stateHandler)
		SocketUtils.registerViewSubscription(view,sessionDeviceListEvent, async (list)=> {
			listHandler(list)
			try {
				// must refresh states after getting new device list.
				stateHandler(await Socket.emit(DEVICES_STATE_SUBSCRIPTION))
			} catch(err) {
				errorHandler(err)
			}
		},sessionId)

		try {
			listHandler(await Socket.emit(SESSION_DEVICE_LIST_EVENT,sessionId))
			stateHandler(await Socket.emit(DEVICES_STATE_SUBSCRIPTION))
		} catch(err) {
			errorHandler(err)
		}
	},

	registerGameEventMessageSubscription(view,callback) {
		SocketUtils.registerViewSubscription(view, GAME_EVENT_SUBSCRIPTION, callback)
	},
}

export default SocketUtils
