import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/sessionList'
import Toastr from 'toastr'
import _ from 'lodash'
import Path from 'path'

import DatePicker from 'js-datepicker'
import Moment from 'moment'

import Socket from 'lib/socket'
import Language from 'lib/language'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:sessionList')

const DATE_FORMAT = 'DD/MM/YYYY'
const DAYS_LABELS = ['D','L','M','M','J','V','S']
const MONTHS_LABELS = [
	'Janvier',
	'Février',
	'Mars',
	'Avril',
	'Mai',
	'Juin',
	'Juillet',
	'Août',
	'Septembre',
	'Octobre',
	'Novembre',
	'Décembre',
]

function getCurrentDate() {
	const currentDate = new Date()
	return `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}` // month start at 0...
}

function getState(session) {
	if (session.has_started) {
		if (session.has_ended)
			return 'Ended'
		return 'Running'
	}
	return'Pending'
}

function parseDate(datestr) {
	return Moment(datestr,'YYYY-MM-DD').toDate()
}

const SessionListView = Marionette.View.extend({
	template: template,
	className: 'sessionList container',

	ui: {
		dateSelector: '#dateSelector',
		dateDisplay: '#dateDisplay',
	},

	events: {
		'click #dateSelector > *': 'showDateSelector',
		'click .session': 'handleSessionSelect',
	},

	initialize() {
		debug('Initializing SessionListView:',this.options)
		this.refreshList()
	},

	templateContext() {
		return {
			createLink: `\#${Path.dirname(this.options.currentPath)}/sessioncreate`,
		}
	},

	serializeData() {
		return {
			sessions: _.isEmpty(this.sessions)?null:this.sessions,
		}
	},

	async refreshList(referenceDate) {
		referenceDate ??= new Date()
		try {
			this.sessions = null
			const result = await Socket.emit('session:list',referenceDate)
			debug('Received sessions:',result)
			// await this.ensureRendered()

			this.referenceDate = referenceDate
			const currentDate = getCurrentDate()
			this.minDate = (currentDate > result.min_date)?result.min_date:currentDate
			this.maxDate = (currentDate < result.max_date)?result.max_date:currentDate

			this.sessions = this.parseSessions(result.sessions)
			this.render()
		} catch(err) {
			console.error(err)
			Toastr.error(`Error listing sessions: ${err}`)
		}
	},

	parseSessions(source) {
		source ??= []
		source.forEach((session)=> {
			session.languageFlag = Language.getFlag(session.language)
			session.state = getState(session)
		})

		// sort by creation date desc.
		_.sortBy(source,'creation_date')
		source.reverse()
		return source
	},

	onRender() {
		if (!this.sessions)
			return

		this.setDisplayedDate(this.referenceDate)
		this.datePicker = DatePicker(this.ui.dateSelector[0], {
			dateSelected: this.referenceDate,
			minDate: parseDate(this.minDate),
			maxDate: parseDate(this.maxDate),
			customDays: DAYS_LABELS,
			customMonths: MONTHS_LABELS,
		})

		this.datePicker.onSelect = (picker,date)=> {
			this.refreshList(date)
		}
	},

	showDateSelector(event) {
		event.stopPropagation() // picker won't show if not present.
		this.datePicker.show()
	},

	setDisplayedDate(date) {
		if (date != null)
			this.ui.dateDisplay.text(Moment(date).format(DATE_FORMAT))
	},

	handleSessionSelect(event) {
		const currentSession = event.currentTarget
		const sessionId = currentSession.dataset.id
		debug('Session selected:',sessionId)
		this.trigger('navigate', Path.join(this.options.currentPath,sessionId))
	},
})

export default SessionListView
