import {Events} from 'backbone'
import _ from 'lodash'

import Debug from 'debug'
const debug = Debug('pandore:lib:auth')

const LOGIN_EVENT = 'login'
const LOGOUT_EVENT = 'logout'

let _currentUser = null
let _socket = {
	emit() {
		throw new Error('Socket has not been properly initialized.')
	},
}

const Auth = {
	initialize(socket) {
		_socket = socket
	},

	setUser(newUser) {
		_currentUser = newUser
		Auth.trigger(LOGIN_EVENT,_currentUser)
	},

	getUser() {
		return _currentUser
	},

	isLoggedIn() {
		return !!_currentUser
	},

	async login(username,password) {
		const result = await _socket.emit(LOGIN_EVENT,username,password)
		debug('Login success',result)
		_currentUser = result
		Auth.trigger(LOGIN_EVENT,_currentUser)
		return _currentUser
	},

	async logout() {
		try {
			await _socket.emit(LOGOUT_EVENT)
		} catch (err) {
			console.error('Error during logout:',err)
		}

		_currentUser = null
		Auth.trigger(LOGOUT_EVENT)
	},
}

_.extend(Auth,Events)

export default Auth
