import Marionette from 'backbone.marionette'
import Focus from 'lib/focus'
import 'bootstrap' // Make sure bootstrap is loaded
import $ from 'jquery'

import Debug from 'debug'
const debug = Debug('pandore:lib:modal')

function createRegion() {
	// $el = $('<div class="modal fade" role="dialog">') // Removed 'fade' class that prevents 'shown' event from firing.
	const $el = $('<div class="modal" role="dialog" data-backdrop="static" tabindex="-1">') // tabindex is required to allow closing modal with 'escape' key.
	$el.appendTo(document.body)

	return new Marionette.Region({
		el: $el,
	})
}

const region = createRegion()
let currentView = false

region.$el.on('hide.bs.modal', ()=> {
	debug('Modal hide.')
	region.currentView?.triggerMethod('modal:hide')
})

region.$el.on('hidden.bs.modal', ()=> {
	debug('Modal is hidden.')
	region.currentView?.triggerMethod('modal:hidden')
	region.empty() // remove dom structure on close.
})

region.$el.on('show.bs.modal', ()=> {
	debug('Modal show.')
	region.currentView?.triggerMethod('modal:show')
})

region.$el.on('shown.bs.modal', ()=> {
	debug('Modal is shown.')
	region.currentView?.triggerMethod('modal:shown')
})

function showModal() {
	region.$el.modal()
}

function hideModal() {
	region.$el.modal('hide')
}

function stopListening() {
	if (currentView) {
		currentView.off('attach')
		currentView.off('modal:close')
	}
}

const Modal = {
	show(view) {
		Focus.clear()
		stopListening()

		view.on('attach',showModal)

		view.on('modal:close', ()=> {
			hideModal()
			stopListening()
			currentView = false
		})

		region.show(view)
		currentView = view
	},

	hide() {
		hideModal()
		stopListening()
		currentView = false
	},
}

export default Modal
