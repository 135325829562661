import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<form class=\"loginBox\" method=\"post\" target=\"hiddenFrame\" action=\"noop\">\n	<h1>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"AppMetadata") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n	<div class=\"messageZone\"></div>\n\n	<input id=\"usernameInput\" autocomplete=\"username\" type=\"text\" placeholder=\"Login\" class=\"form-control\" required=\"true\"></input>\n	<input id=\"passwordInput\" autocomplete=\"current-password\" type=\"password\" placeholder=\"Password\" class=\"form-control\" required=\"true\"></input>\n	<div class=\"controls\">\n		<div class=\"spacer\"></div>\n		<button type=\"submit\" class=\"btn btn-primary submitButton\">Submit</button>\n	</div>\n</form>\n\n<div class=\"details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"AppMetadata") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " v"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"AppMetadata") : depth0)) != null ? lookupProperty(stack1,"version") : stack1), depth0))
    + "</div>\n\n<iframe style=\"display:none\" name=\"hiddenFrame\"></iframe>\n";
},"useData":true});