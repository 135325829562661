import $ from 'jquery'

const FileUtils = {
	forceDownload(blob,filename) {
		const el = document.createElement('a')
		el.setAttribute('href',URL.createObjectURL(blob))
		el.setAttribute('download',filename)
		el.style.display = 'none'
		document.body.appendChild(el)
		el.click()
		document.body.removeChild(el)
	},

	forceDataDownload(data,filename) {
		const blob = new Blob([data], {type:'text/plain'})
		FileUtils.forceDownload(blob,filename)
	},

	selectFile(acceptedFileExtensions) {
		return new Promise((resolve,reject)=> {
			const el = document.createElement('input')
			el.setAttribute('type', 'file')
			if (acceptedFileExtensions != null)
				el.setAttribute('accept', acceptedFileExtensions)
			el.click()
			el.oninput = ()=> {
				if (el.files.length != 1) {
					reject(new Error('No file selected.'))
					return
				}
				resolve(el.files[0])
			}
		})
	},

	upload(serviceUrl,data) {
		return new Promise((resolve,reject)=> {
			const formData = new FormData()
			for (const [key, val] of Object.entries(data))
				formData.append(key, val)

			$.ajax({
				url: serviceUrl,
				type: 'POST',
				cache: false,
				data: formData,
				processData: false, // prevent jquery from trying to interpret formdata object
				contentType: false, // let jquery set content type.
				success(data,textStatus,jqXHR) {
					if (data.error)
						reject(data.error)
					else
						resolve(data)
				},

				error(jqXHR, textStatus, errorThrown) {
					console.warn('Upload request error:',jqXHR,textStatus,errorThrown)
					reject(new Error(jqXHR.responseText || errorThrown))
				},
			})
		})
	},
}

export default FileUtils
