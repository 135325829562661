
import {Application} from 'backbone.marionette'
import Backbone from 'backbone'

import _ from 'lodash'

import Auth from 'lib/auth'
import Socket from 'lib/socket'
import LoginView from 'views/login'
import HomeView from 'views/home'

import CommonLayoutView from 'views/common/layout'

import AdminUserListView from 'views/admin/userList'
import AdminUserEditorView from 'views/admin/userEditor'
import AdminActionsView from 'views/admin/actions'
import AdminGameCodeView from 'views/admin/gameCode'
import AdminExternalModuleView from 'views/admin/externalModules'
import AdminMuninView from 'views/admin/munin'

import SessionListView from 'views/gamemaster/sessionList'
import SessionCreateView from 'views/gamemaster/sessionCreate'
import SessionDetailsView from 'views/gamemaster/sessionDetails'
import DeviceListView from 'views/gamemaster/deviceList'
import DeviceMessagesView from 'views/gamemaster/deviceMessages'
import GameEventView from 'views/gamemaster/gameEvents'
import GameListView from 'views/gamemaster/gameList'
import MidiTestView from 'views/gamemaster/midiTest'

import Debug from 'debug'
const debug = Debug('pandore:app')

const LAYOUT_MAP = {
	admin: {
		defaultView: 'users',
		navItems: [{
			label: 'Users',
			link: '',
		},{
			label: 'GameCode',
			link: 'gamecode',
		},{
			label: 'ExtModules',
			link: 'externalmodules',
		},{
			label: 'Munin',
			link: 'munin',
		},{
			label: 'Actions',
			link: 'actions',
		}],

		views: {
			users: {
				class: AdminUserListView,
				subview: AdminUserEditorView,
			},
			gamecode: {
				class: AdminGameCodeView,
			},
			externalmodules: {
				class: AdminExternalModuleView,
			},
			munin: {
				class: AdminMuninView,
			},
			actions: {
				class: AdminActionsView,
			},
		},
	},

	gamemaster: {
		defaultView: 'sessions',
		navItems: [{
			label: 'Sessions',
			link: '',
		},{
			label: 'Devices',
			link: 'devices',
		},{
			label: 'GameEvents',
			link: 'gameevents',
		},{
			label: 'Games',
			link: 'games',
		},{
			label: 'MIDI',
			link: 'midi',
		}],
		views: {
			sessions: {
				class: SessionListView,
				subview: SessionDetailsView,
			},
			sessioncreate: {
				class: SessionCreateView,
			},
			devices: {
				class: DeviceListView,
				subview: DeviceMessagesView,
			},
			gameevents: {
				class: GameEventView,
			},
			games: {
				class: GameListView,
			},
			midi: {
				class: MidiTestView,
			},
		},
	},
}

const BackofficeRouter = Backbone.Router.extend({
	initialize(options) {
		debug('Initializing BackofficeRouter:',options)
		this.showView = options.showView
		this.getCurrentView = options.getCurrentView

		Auth.on('logout',this.redirectToLogin,this)
		Auth.on('login',this.redirectHome,this)
	},

	routes: {
		'login': 'showLogin',
		'home': 'showHome',
		':section(/*subpath)': 'processLayout',
		'*uri': 'redirectHome', // catch all.
	},

	navigateTrigger(route,options) {
		this.navigate(route, _.assign({},options,{trigger:true}))
	},

	redirectToLogin() {
		this.navigateTrigger('login')
	},

	redirectHome() {
		debug('Redirecting to root.')
		this.navigateTrigger('home')
	},

	checkLoggedIn(callback) {
		if (Auth.isLoggedIn()) {
			callback()
		} else {
			debug('User not logged in, redirecting to login form.')
			this.redirectToLogin()
		}
	},

	processLayout(section,subpath) {
		this.checkLoggedIn(()=> {
			if (!LAYOUT_MAP[section])
				return this.redirectHome()

			const layoutClass = LAYOUT_MAP[section].class || CommonLayoutView
			const currentView = this.getCurrentView()

			const subpathItems = subpath ? subpath.split('/') : []

			if (currentView instanceof layoutClass && currentView.getSection && currentView.getSection() == section) {
				// keep existing view.
				currentView.updateSubpath(subpathItems)
			} else {
				const view = new layoutClass(_.assign({},LAYOUT_MAP[section],{
					section: section,
					subpath: subpathItems,
				}))

				view.on('navigate',this.navigateTrigger,this)
				this.showView(view)
			}
		})
	},

	showLogin() {
		if (Auth.isLoggedIn()) {
			debug('Preventing logged in user to use the login form.')
			this.redirectHome()
		} else {
			const view = new LoginView()
			this.showView(view)
		}
	},

	showHome() {
		this.checkLoggedIn(()=> {
			const view = new HomeView()
			this.showView(view)
		})
	},
})

const BackofficeApp = Application.extend({
	initialize() {
		debug('Initializing application:',this.options)
	},

	async onStart() {
		Auth.initialize(Socket)
		const welcomeData = await Socket.connect()
		if (welcomeData?.currentUser) {
			debug('User is connected:',welcomeData.currentUser)
			Auth.setUser(welcomeData.currentUser)
		}

		this.router = new BackofficeRouter({
			showView: this.showView.bind(this),
			getCurrentView: ()=> {
				return this.getRegion().currentView
			}
		})

		if (!Backbone.history.start())
			throw new Error('Backbone history could not start.')
	},
})

export default BackofficeApp
