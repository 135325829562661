
import $ from 'jquery'
import Time from 'lib/time'
import Focus from 'lib/focus'

export default {
	makeLongpressAvailable() {
		$.fn.enableLongpress = function(duration) {
			duration ??= 3

			this.each(function(index,el) {
				const $el = $(this)

				$el.addClass('longpressSource')
				$el.data('toggle','tooltip')

				let progressbar = null
				let activationTimer = null
				let activationStartMillis = null

				$el.tooltip({
					trigger: 'manual',
					// placement: 'bottom',
					html: true,
					title: ()=> {
						progressbar = document.createElement('progress')
						progressbar.className = 'longpress'
						return progressbar
					},
				})

				function startTimer() {
					$el.tooltip('show')
					progressbar.value = 0
					progressbar.max = duration*1000
					activationStartMillis = Date.now()

					activationTimer = setInterval(async ()=> {
						progressbar.value = Date.now()-activationStartMillis

						if (progressbar.value < progressbar.max)
							return

						// timer ended.
						clearTimer()
						$el.addClass('flash')
						await Time.wait(100)
						$el.removeClass('flash')
						$el.trigger('longpress')
					},200)
				}

				function clearTimer() {
					$el.tooltip('hide')
					Focus.clear()
					activationStartMillis = null
					clearInterval(activationTimer)
					activationTimer = null
				}

				$el.on('click', (event)=> {
					// disable normal click.
					event.stopPropagation()
					event.preventDefault()
				})

				$el.on('doubleclick', (event)=> {
					// disable normal click.
					event.stopPropagation()
					event.preventDefault()
				})

				$el.on('pointerdown', ()=> {
					if (!el.disabled)
						startTimer()
				})

				$el.on('pointerup', clearTimer)
				$el.on('pointerleave', clearTimer)
			})
		}
	},
}
