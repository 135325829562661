import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn dropdown-toggle mainButton\" id=\"device-item-button-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\" data-toggle=\"dropdown\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"deviceTypeIcon"),depth0,{"name":"deviceTypeIcon","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n</button>\n<div class=\"dropdown-menu\" aria-labelledby=\"device-item-button-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\">\n	<button class=\"dropdown-item removeButton\">Remove</button>\n</div>\n";
},"usePartial":true,"useData":true});