import * as Handlebars from 'handlebars/runtime';
import formatDate from 'templates/helpers/formatDate';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({formatDate});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "inbound";
},"3":function(container,depth0,helpers,partials,data) {
    return "outbound";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn btn-link btn-sm resendButton\" data-original-message=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"originalMessage") : depth0), depth0))
    + "\" title=\"Resend\"><i class=\"far fa-redo\"></i></button><button class=\"btn btn-link btn-sm copyButton\" data-original-message=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"originalMessage") : depth0), depth0))
    + "\" title=\"Copy to input\"><i class=\"far fa-copy\"></i></button>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n	class=\"message "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInbound") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":16},"end":{"line":2,"column":63}}})) != null ? stack1 : "")
    + "\"\n>"
    + alias2(lookupProperty(helpers,"formatDate").call(alias1,(depth0 != null ? lookupProperty(depth0,"time") : depth0),"HH:mm:ss",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":3,"column":31}}}))
    + " - "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInbound") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":3,"column":51},"end":{"line":3,"column":376}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});