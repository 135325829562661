import Marionette from 'backbone.marionette'
import template from 'templates/login'

import AppMetadata from 'metadata'

import Auth from 'lib/auth'

import Debug from 'debug'
const debug = Debug('pandore:views:login')

const ERROR_MAP = {
	ERROR: 'Server Error.',
	AUTHENTICATION_FAILED: 'Invalid login or password.',
}

const LoginView = Marionette.View.extend({
	template: template,
	className: 'login',

	ui: {
		usernameInput: '#usernameInput',
		passwordInput: '#passwordInput',
		messageZone: '.messageZone',
		iframe: 'iframe',
	},

	events: {
		'submit form': 'handleFormSubmit',
	},

	initialize() {
		debug('Initializing LoginView:',this.options)
	},

	templateContext() {
		return {AppMetadata}
	},

	handleFormSubmit(event) {
		debug('Form submitted')
		this.ui.messageZone.empty()

		this.ui.iframe.one('load', async ()=> {
			try {
				await Auth.login(this.ui.usernameInput.val(),this.ui.passwordInput.val())
			} catch(err) {
				console.error('Login error:',err)
				this.ui.messageZone.text(ERROR_MAP[err] || err)
				this.ui.passwordInput.focus().select()
			}
		})
	},
})

export default LoginView
