import Marionette from 'backbone.marionette'
import template from 'templates/common/devicePickerModal'
import itemTemplate from 'templates/common/devicePickerModal/item'

import Backbone from 'backbone'
import Toastr from 'toastr'
import _ from 'lodash'

import Socket from 'lib/socket'
import SocketUtils from 'lib/socket/utils'
import DeviceName from 'lib/deviceName'

import Debug from 'debug'
const debug = Debug('pandore:views:common:devicePickerModal')

const CONNECTED_CLASS = 'connected'
const DISABLED_ATTR = 'disabled'

const DeviceView = Marionette.View.extend({
	template: itemTemplate,
	className: 'device',

	triggers: {
		'change @ui.checkbox': 'change',
	},

	ui: {
		checkbox: 'input',
	},

	initialize() {
		debug('Initializing DeviceView:',this.options)
	},

	isSelected() {
		return this.ui.checkbox.prop( 'checked')
	},

	async setConnected(flag) {
		await this.ensureRendered()
		this.$el.toggleClass(CONNECTED_CLASS,flag)
	},

	getUuid() {
		return this.model.get('uuid')
	},

	getType() {
		return this.model.get('type')
	},

	getManufacturer() {
		return this.model.get('manufacturer')
	},

	getModel() {
		return this.model.get('model')
	},

	templateContext() {
		return {
			modelName: DeviceName.lookup(this.getManufacturer(),this.getModel()),
		}
	},
})

const DevicePickerModalView = Marionette.CollectionView.extend({
	template: template,
	className: 'devicePicker modal-dialog modal-dialog-centered modal-dialog-scrollable',

	childView: DeviceView,
	childViewContainer: '.modal-body',
	childViewEvents: {
		change: 'handleSelectionChange',
	},

	ui: {
		submitButton: '.submitButton',
	},

	events: {
		'click .submitButton': 'handleSubmit',
	},

	setSubmitDisabled(flag) {
		// await this.ensureRendered()
		if (flag)
			this.ui.submitButton.attr(DISABLED_ATTR,'true')
		else
			this.ui.submitButton.removeAttr(DISABLED_ATTR)
	},

	initialize() {
		debug('Initializing DevicePickerModalView:',this.options)
		this.collection = new Backbone.Collection()
		this.collection.comparator = 'sortName'

		SocketUtils.registerDeviceSubscriptions(this,this.updateDeviceList,this.updateDisplayedStates,(err)=> {
			console.error(err)
			Toastr.error(`Echec du listage des appareils : ${err}`)
		})
	},

	onRender() {
		this.setSubmitDisabled(true)
	},

	parseList(list) {
		list.forEach((item)=> {
			item.id = item.uuid
			item.sortName = item.name.toLowerCase()
		})
		return list
	},

	updateDeviceList(list) {
		debug('Received list:',list)
		this.collection.reset(this.parseList(list))
	},

	updateDisplayedStates(states) {
		this.children.each((item)=> {
			item.setConnected(states.includes(item.getUuid()))
		})
	},

	getSelection() {
		const selection = []
		this.children.each((item)=> {
			if (item.isSelected())
				selection.push(item.getUuid())
		})
		return selection
	},

	handleSubmit() {
		this.trigger('submit',this.getSelection())
		this.trigger('modal:close') // don't close before getting current selection.
	},

	handleSelectionChange() {
		this.setSubmitDisabled(_.isEmpty(this.getSelection()))
	},
})

export default DevicePickerModalView
