import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/sessionDetails'
import Backbone from 'backbone'
import Toastr from 'toastr'
import _ from 'lodash'

import Socket from 'lib/socket'
import SocketUtils from 'lib/socket/utils'
import Language from 'lib/language'
import DateUtils from 'lib/date'
import Time from 'lib/time'

import DeviceListView from 'views/gamemaster/sessionDetails/deviceList'
import MessagingConsoleView from 'views/common/messagingConsole'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:sessionDetails')

const TIMER_INTERVAL = 200

function addPadd(number, charLength) {
	let result = number.toString()

	while (result.length < charLength)
		result = `0${result}`

	return result
}

function secondsToTime(totalSeconds,showHours) {
	totalSeconds = parseInt(totalSeconds,10) // in case we have a string.
	const secs = totalSeconds%60
	let rest = Math.floor(totalSeconds/60)
	const mins = rest%60
	const hours = Math.floor(rest/60)

	let result = `${addPadd(mins,2)}:${addPadd(secs,2)}`
	if (showHours ?? true)
		result = `${addPadd(hours,2)}:${result}`
	return result
}

function getDateDiff(date1,date2,roundDown) {
	const millis = new Date(date2) - new Date(date1)
	const secs = millis/1000

	if (roundDown)
		return Math.floor(secs)
	return Math.ceil(secs)
}

const SessionDetailsView = Marionette.View.extend({
	template: template,
	className: 'sessionDetails container',

	regions: {
		devices: '#deviceRegion',
		messages: '#messageRegion',
	},

	ui: {
		controlButtons: '.controlButton',
		startButton: '.startButton',
		endButton: '.endButton',
		skipButton: '.skipButton',
		statusDisplay: '#statusDisplay',
		sessionEndDateLabel: '.sessionEndDate',
		timeContainer: '#timeContainer',
		addTimeButton: '.addTimeButton',
	},

	modelEvents: {
		change: 'updateStartEndState',
	},

	events: {
		'longpress @ui.startButton': 'handleStart',
		'longpress @ui.endButton': 'handleStop',
		'longpress @ui.addTimeButton': 'handleAddTime',
		'longpress @ui.skipButton': 'handleSkip',
	},

	bindings: {
		'#idLabel': {
			observe: 'id',
			onGet: (value)=> {
				if (value)
					return `\#${value}`
				return''
			},
		},
		'#teamNameLabel': 'team_name',
		'#languageFlag': {
			observe: 'language',
			onGet: (value)=> Language.getFlag(value),
		},
		'#statusDisplay': 'status_text',
	},

	async initialize() {
		debug('Initializing SessionDetailsView:',this.options)
		this.model = new Backbone.Model()

		this.sessionId = _.head(this.options.remainingPath)

		if (!this.sessionId) {
			Toastr.error('Session id is missing.')
			return
		}

		SocketUtils.registerViewSubscription(this,`session:details:${this.sessionId}`,this.updateSessionData)
		await this.refreshSessionData()
	},

	onBeforeDetach() {
		this.stopTimer()
	},

	async updateStartEndState() {
		debug('Updating buttons state.',this.model)
		await this.ensureRendered()

		if (this.hasSessionEnded()) {
			this.ui.controlButtons.hide()
			this.ui.sessionEndDateLabel.text('Ended at: '+DateUtils.formatTime(this.getEndDate())).show()
			this.deviceList.setReadOnly()
		} else if (this.hasSessionStarted()) {
			this.ui.controlButtons.hide()
			this.ui.endButton.show()
			this.ui.skipButton.show()
			this.ui.statusDisplay.show()
			this.ui.addTimeButton.show()
		} else {
			this.ui.controlButtons.hide()
			this.ui.startButton.show()
		}
	},

	getStartDate() {
		return this.model.get('start_date')
	},

	getEndDate() {
		return this.model.get('end_date')
	},

	getExpectedEndDate() {
		return this.model.get('expected_end_date')
	},

	hasSessionStarted() {
		return this.model.get('has_started')
	},

	hasSessionEnded() {
		return this.model.get('has_ended')
	},

	isSessionRunning() {
		return this.hasSessionStarted() && !this.hasSessionEnded()
	},

	isSessionPending() {
		return !this.hasSessionStarted()
	},

	async setStatus(text) {
		await this.ensureRendered()
		this.ui.statusDisplay.text(text)
	},

	onRender() {
		this.stickit()

		this.ui.startButton.enableLongpress(1.5)
		this.ui.endButton.enableLongpress()
		this.ui.addTimeButton.enableLongpress(2)
		this.ui.skipButton.enableLongpress(1)

		this.deviceList = new DeviceListView({
			sessionId: this.sessionId,
			isReadOnly: this.hasSessionEnded(),
		})
		this.showChildView('devices',this.deviceList)

		const messagingConsole = new MessagingConsoleView({
			noFocus: true,
		})

		messagingConsole.on('submit', async (message)=> {
			try {
				await Socket.emit('session:hint',this.sessionId,message)
				messagingConsole.addOutboundMessage(message,message)
			} catch(err) {
				Toastr.error `Error sending hint: ${err}`
			}
		})
		this.showChildView('messages',messagingConsole)
	},

	async refreshSessionData() {
		try {
			const sessionData = await Socket.emit('session:details',this.sessionId)
			// debug('Received session details:',sessionData)
			this.updateSessionData(sessionData)
		} catch(err) {
			console.error('Error fetching session:',err)
			Toastr.error(`Error fetching session: ${err}`)
		}
	},

	updateSessionData(sessionData) {
		if (!sessionData) {
			Toastr.error(`Id de session invalide: ${this.sessionId}`)
			return
		}

		debug('Received session data:',sessionData)
		this.model.set(sessionData)
		this.updateTimer()
	},

	updateTimer() {
		this.ensureRendered()

		let value = 0
		if (this.hasSessionEnded())
			value = getDateDiff(this.getStartDate(),this.getEndDate(),true)
		else if (this.hasSessionStarted())
			value = getDateDiff(new Date(),this.getExpectedEndDate())

		this.ui.timeContainer.text(secondsToTime(value))

		if (!this.isSessionRunning()) {
			this.stopTimer()
			return
		}

		if (!this.timerInterval)
			this.timerInterval = Time.setInterval(TIMER_INTERVAL,this,this.updateTimer)
	},

	stopTimer() {
		clearInterval(this.timerInterval)
		this.timerInterval = null
	},

	async handleStart() {
		try {
			await Socket.emit('session:start',this.sessionId)
		} catch(err) {
			console.error('Error starting session:',err)
			Toastr.error(`Error starting session: ${err}`)
		}
	},

	async handleStop() {
		try {
			await Socket.emit('session:end',this.sessionId)
		} catch(err) {
			console.error('Error endding session:',err)
			Toastr.error(`Error endding session: ${err}`)
		}
	},

	async handleAddTime() {
		try {
			await Socket.emit('session:addtime',this.sessionId)
		} catch(err) {
			console.error('Error adding time to session:',err)
			Toastr.error(`Error adding time to session: ${err}`)
		}
	},

	async handleSkip() {
		try {
			await Socket.emit('session:skip',this.sessionId)
		} catch(err) {
			console.error('Error skipping session:',err)
			Toastr.error(`Error Skipping session: ${err}`)
		}
	},
})

export default SessionDetailsView
