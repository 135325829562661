
import _ from 'lodash'

function bindCallback(obj,callback) {
	if (_.isFunction(obj)) {
		// we don't have an object to bind.
		callback = obj
	} else {
		callback = callback.bind(obj)
	}

	return callback
}

const Time = {
	setTimeout(duration,obj,callback) {
		return setTimeout(bindCallback(obj,callback), duration)
	},

	setInterval(duration,obj,callback) {
		return setInterval(bindCallback(obj,callback), duration)
	},

	getDuration(recordedDate) {
		return Date.now() - recordedDate
	},

	wait(millis) {
		return new Promise((resolve)=> {
			setTimeout(resolve,millis || 0)
		})
	},
}

export default Time
