import Marionette from 'backbone.marionette'
import template from 'templates/admin/actions'

import Debug from 'debug'
const debug = Debug('pandore:views:admin:actions')

const AdminActionsView = Marionette.View.extend({
	template: template,
	className: 'actions container',

	initialize() {
		debug('Initializing AdminActionsView:',this.options)
	},
})

export default AdminActionsView
