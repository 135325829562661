import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n				<option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n			";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>New Session</h1>\n\n<form>\n	<div class=\"form-group row\">\n		<label for=\"teamNameInput\" class=\"col-sm-2 col-form-label\">Team Name</label>\n		<div class=\"col-sm-4\">\n			<input type=\"text\" class=\"form-control\" id=\"teamNameInput\" maxlength=\"70\" required=\"true\">\n		</div>\n	</div>\n	<div class=\"form-group row\">\n		<label for=\"externalIdInput\" class=\"col-sm-2 col-form-label\">4escape Id</label>\n		<div class=\"col-sm-4\">\n			<input type=\"text\" class=\"form-control\" id=\"externalIdInput\" maxlength=\"100\" placeholder=\"Optional...\">\n		</div>\n	</div>\n	<div class=\"form-group row\">\n		<label for=\"languageInput\" class=\"col-sm-2 col-form-label\">Language</label>\n		<div class=\"col-sm-2\">\n			<select id=\"languageInput\" class=\"form-control\" required=\"true\">\n			<!-- "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"availableLanguages") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":22,"column":12}}})) != null ? stack1 : "")
    + " -->\n			</select>\n		</div>\n	</div>\n	<div class=\"form-group row\">\n		<label for=\"gameInput\" class=\"col-sm-2 col-form-label\">Game</label>\n		<div class=\"col-sm-4\">\n			<select id=\"gameInput\" class=\"form-control\" required=\"true\"></select>\n		</div>\n	</div>\n	<div class=\"form-group\">\n		<button class=\"btn btn-primary\">Submit</button>\n	</div>\n</form>\n";
},"useData":true});