import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/deviceMessages'
import Toastr from 'toastr'
import _ from 'lodash'
import Moment from 'moment'

import Socket from 'lib/socket'
import SocketUtils from 'lib/socket/utils'

import MessagingConsoleView from 'views/common/messagingConsole'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:deviceMessages')

const DEVICE_MESSAGE_RECEIVED_SUBSCRIPTION = 'device:message:received'

const CONNECTED_CLASS = 'connected'

const DEFAULT_DEVICE_NAME = 'Inconnu'

const TOKEN_SEPARATOR = ','

function formatMessage(message,args) {
	let result = message
	if (!_.isEmpty(args)) {
		const formatedArgs = _.map(args, (value)=> JSON.stringify(value))
		result += ` (${formatedArgs.join(', ')})`
	}

	return result
}

const DeviceMessagesView = Marionette.View.extend({
	template: template,
	className: 'deviceMessages container',

	regions: {
		messageRegion: '#messageRegion',
	},

	ui: {
		nameLabel: '.deviceName',
		state: '.state',
		stateLabel: '.stateLabel',
	},

	initialize() {
		debug('Initializing DeviceMessagesView:',this.options)
		this.deviceUuid = _.head(this.options.remainingPath)

		SocketUtils.registerDeviceSubscriptions(this,this.updateDeviceList,this.updateStateDisplay,(err)=> {
			console.error(err)
			Toastr.error(`Error listing devices: ${err}`)
		})

		SocketUtils.registerViewSubscription(this,`${DEVICE_MESSAGE_RECEIVED_SUBSCRIPTION}:${this.deviceUuid}`,this.handleMessageReceived)
	},

	onRender() {
		this.messagingConsole = new MessagingConsoleView()
		this.messagingConsole.on('submit', this.handleMessageSend, this)
		this.showChildView('messageRegion',this.messagingConsole)
	},

	handleMessageSend(str) {
		const [message,...args] = str.split(TOKEN_SEPARATOR)
		Socket.emit('device:message:send',this.deviceUuid,message,...args)
		this.messagingConsole.addOutboundMessage(formatMessage(message,args),str)
	},

	async updateDeviceList(deviceList) {
		debug('Updating devices:',deviceList)
		await this.ensureRendered()
		for (const device of deviceList || []) {
			if (device.uuid == this.deviceUuid) {
				this.ui.nameLabel.text(device.name)
				return
			}
		}
		this.ui.nameLabel.text(DEFAULT_DEVICE_NAME)
	},

	async updateStateDisplay(stateList) {
		debug('Updating state:',stateList)
		await this.ensureRendered()
		this.ui.state.toggleClass(CONNECTED_CLASS,stateList.includes(this.deviceUuid))
	},

	async handleMessageReceived(msg,...args) {
		debug('Received device message:',msg,...args)
		await this.ensureRendered()
		this.messagingConsole.addInboundMessage(formatMessage(msg,args))
	},
})

export default DeviceMessagesView
