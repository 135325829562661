import Marionette from 'backbone.marionette'
import template from 'templates/common/layout'

import _ from 'lodash'
import Path from 'path'

import AppMetadata from 'metadata'
import Auth from 'lib/auth'

import Debug from 'debug'
const debug = Debug('pandore:views:common:layout')

const NAVIGATE_EVENT = 'navigate'
const ACTIVE_CLASS = 'active'

const CommonLayoutView = Marionette.View.extend({
	template: template,

	regions: {
		mainRegion: '#mainRegion',
	},

	ui: {
		tabs: '.navbar .navbar-nav .nav-link',
	},

	events: {
		'click #logoutButton': 'logout',
	},

	className() {
		return `layout ${this.options.section}`
	},

	initialize() {
		debug('Initializing CommonLayoutView:',this.options)

		// defer subpath processing to let parent register event listener.
		_.defer(()=> this.updateSubpath(this.options.subpath))
	},

	formatNavItems(items) {
		return items.map((item)=> {
			// use _.assign to avoid modifying provided configuration.
			return _.assign({},item, {
				fullLink: Path.join(this.getSection(),item.link),
			})
		})
	},

	templateContext() {
		return {
			AppMetadata: AppMetadata,
			User: Auth.getUser(),
			NavItems: this.formatNavItems(this.options.navItems),
		}
	},

	showView(view) {
		this.showChildView('mainRegion',view)
	},

	logout() {
		Auth.logout()
	},

	getSection() {
		return this.options.section
	},

	async updateActiveTab(tabName, isDefault) {
		await this.ensureRendered()
		this.ui.tabs.removeClass(ACTIVE_CLASS)

		this.ui.tabs.each((index,el)=> {
			if (el.dataset.link == tabName || isDefault && _.isEmpty(el.dataset.link))
				el.classList.add(ACTIVE_CLASS)
		})
	},

	updateSubpath(subpathItems) {
		debug('Processing subpath:',subpathItems)
		const currentTab = _.head(subpathItems) ?? this.options.defaultView
		const remainingPath = _.tail(subpathItems)
		debug('Current tab:',currentTab,remainingPath)

		if (!this.options.views[currentTab]) {
			// clearing unknown subpath.
			this.trigger(NAVIGATE_EVENT, Path.join(this.getSection()))
			return
		}

		this.updateActiveTab(currentTab, currentTab == this.options.defaultView)

		const hasRemainingPath = !_.isEmpty(remainingPath)

		if (hasRemainingPath && !this.options.views[currentTab].subview) {
			this.trigger(NAVIGATE_EVENT, `#${currentTab}`)
			return
		}

		let viewClass

		if (hasRemainingPath && this.options.views[currentTab].subview)
			viewClass = this.options.views[currentTab].subview
		else
			viewClass = this.options.views[currentTab].class

		const view = new viewClass({
			remainingPath: remainingPath,
			currentPath: Path.join(this.getSection(),currentTab),
		})

		view.on(NAVIGATE_EVENT, (target)=> {
			this.trigger(NAVIGATE_EVENT,target)
		})

		this.showView(view)
	},
})

export default CommonLayoutView
