import Marionette from 'backbone.marionette'
import template from 'templates/common/messagingConsole'
import messageTemplate from 'templates/common/messagingConsole/message'

import Moment from 'moment'
import _ from 'lodash'

import Debug from 'debug'
const debug = Debug('pandore:views:common:messagingConsole')

const MESSAGE_CLASS = 'message'
const INBOUND_CLASS = 'inbound'
const OUTBOUND_CLASS = 'outbound'

function getCurrentTime() {
	return Moment().format('HH:mm:ss')
}

function createMessageEntry(msg, originalMessage, isInbound) {
	return messageTemplate({
		message: msg,
		originalMessage: originalMessage,
		isInbound: isInbound,
		time: new Date(),
	})
}

const MessagingConsoleView = Marionette.View.extend({
	template: template,
	className: 'messagingConsole',

	ui: {
		messageInput: '.messageInput',
		log: '.log',
	},

	events: {
		'click': 'grabFocus',
		'submit form': 'handleFormSubmit',
		'click .resendButton': 'handleResend',
		'click .copyButton': 'handleCopy',
	},

	initialize() {
		debug('Initializing MessagingConsoleView:',this.options)
	},

	onAttach() {
		this.grabFocus()
	},

	grabFocus() {
		if (!this.options.noFocus)
			this.ui.messageInput.focus()
	},

	scrollLogsToBottom() {
		this.ui.log.scrollTop(this.ui.log.prop('scrollHeight'))
	},

	handleFormSubmit(event) {
		event.preventDefault()
		event.stopPropagation()

		const messageString = this.ui.messageInput.val().trim()

		if (!messageString) {
			debug('Ignoring empty message.')
			return
		}

		this.ui.messageInput.val(null)
		this.sendMessage(messageString)
	},

	sendMessage(msg) {
		this.trigger('submit',msg)
	},

	async _addMessage(isInbound,message,originalMessage) {
		debug('Adding message:',isInbound?'in':'out',message,originalMessage)
		await this.ensureRendered()
		this.ui.log.append(createMessageEntry(message,originalMessage,isInbound))
		this.scrollLogsToBottom()
	},

	addInboundMessage(message) {
		this._addMessage(true,message)
	},

	addOutboundMessage(message,original) {
		this._addMessage(false,message,original)
	},

	handleResend(event) {
		// debug('Resend:',event.currentTarget.dataset)
		this.sendMessage(event.currentTarget.dataset.originalMessage)
	},

	handleCopy(event) {
		this.ui.messageInput.val(event.currentTarget.dataset.originalMessage)
	},
})

export default MessagingConsoleView
