import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"navbarStyle") : depth0), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "navbar-dark bg-dark";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<li class=\"nav-item\">\n					<a class=\"nav-link\" href=\"#"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fullLink") : depth0), depth0))
    + "\" data-link=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a>\n				</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"navbar navbar-expand-lg "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"navbarStyle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":104}}})) != null ? stack1 : "")
    + "\">\n	<div class=\"container\">\n		<a class=\"navbar-brand\" href=\"#\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"AppMetadata") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " <span class=\"appVersion\">v"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"AppMetadata") : depth0)) != null ? lookupProperty(stack1,"version") : stack1), depth0))
    + "</span></a>\n\n		<button class=\"navbar-toggler\" type=\"button\" data-toggle=\"collapse\" data-target=\"#navbarText\" aria-controls=\"navbarText\" aria-expanded=\"false\" aria-label=\"Toggle navigation\">\n			<span class=\"navbar-toggler-icon\"></span>\n		</button>\n		<div class=\"collapse navbar-collapse\" id=\"navbarText\">\n			<ul class=\"navbar-nav mr-auto\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"NavItems") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "			</ul>\n			<div class=dropdown>\n				<a class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\" href=\"#\" role=\"button\" aria-haspopup=\"true\" aria-expanded=\"false\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>\n				<div class=\"dropdown-menu dropdown-menu-right\">\n					<button class=\"dropdown-item\" id=\"logoutButton\">Logout</button>\n				</div>\n			</div>\n		</div>\n	</div>\n</nav>\n";
},"useData":true});