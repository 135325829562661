import * as Handlebars from 'handlebars/runtime';
import ifEquals from 'templates/helpers/ifEquals';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({ifEquals});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "	<i class=\"far fa-mobile\" title=\"Téléphone\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"ifEquals").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"tablet",{"name":"ifEquals","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":1},"end":{"line":8,"column":14}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"far fa-tablet\" title=\"Tablette\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"far fa-desktop\" title=\"Ordinateur\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"ifEquals").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"mobile",{"name":"ifEquals","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":13}}})) != null ? stack1 : "");
},"useData":true});