
import io from 'socket.io-client'

import Debug from 'debug'
const debug = Debug('pandore:lib:socket')

const CHANNEL_NAME = '/backoffice'
const DEFAULT_OPTIONS = {
	reconnection: true,
	reconnectionAttempts: Infinity,
	timeout: 1000,
	transports: ['websocket'],
}

let _socket = null

const Socket = {
	connect(query) {
		return new Promise((resolve)=> {
			debug('Connecting socket to channel:',CHANNEL_NAME)
			const options = Object.assign({},DEFAULT_OPTIONS,{query})
			debug('Using options:',options)

			_socket = io(CHANNEL_NAME,options)

			_socket.on('connect', ()=> {
				debug('Socket connected!', _socket)
			})

			_socket.once('welcome', (data)=> {
				debug('Welcome received:',data)
				resolve(data)
			})
		})
	},

	getSocket() {
		return _socket
	},

	emit(...args) {
		debug('Emitting:',...args)
		return new Promise((resolve,reject)=> {
			_socket.emit(...args, (err,result)=> {
				if (err)
					reject(err)
				else
					resolve(result)
			})
		})
	},

	on(...args) {
		_socket.on(...args)
	},

	off(...args) {
		_socket.off(...args)
	},

	once(...args) {
		_socket.once(...args)
	},

	hasListeners(...args) {
		_socket.hasListeners(...args)
	},
}

export default Socket
