import Marionette from 'backbone.marionette'
import template from 'templates/admin/munin'
import Toastr from 'toastr'

import Socket from 'lib/socket'

import Debug from 'debug'
const debug = Debug('pandore:views:admin:munin')

const AdminMuninView = Marionette.View.extend({
	template: template,
	className: 'munin container',

	initialize() {
		debug('Initializing AdminMuninView:',this.options)
		this.requestData()
	},

	async requestData() {
		try {
			const data = await Socket.emit('munin:data')
			debug('Received data:',data)
			this.data = data
			this.render()
		} catch(err) {
			console.error('Error fetching data:',err)
			Toastr.error(`Error Fetching data: ${err}`)
		}
	},

	serializeData() {
		return {
			nodes: this.data
		}
	},
})

export default AdminMuninView
