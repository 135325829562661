import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<table class=\"table table-striped\">\n		<thead>\n			<tr>\n				<th>Name</th>\n				<th class=\"center\">Language</th>\n				<th class=\"center\">Game Id</th>\n				<th class=\"center\">Version</th>\n				<th>Actions</th>\n			</tr>\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"games") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "		</tbody>\n	</table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr class=\"game vcenter\">\n				<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n				<td class=\"center\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"languageFlag") : depth0), depth0))
    + "</td>\n				<td class=\"center\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"gameId") : depth0), depth0))
    + "</td>\n				<td class=\"center\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"version") : depth0), depth0))
    + "</td>\n\n				<td class=\"novpadding\">\n					<a class=\"btn btn-primary btn-sm downloadButton\" title=\"Download\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"downloadLink") : depth0), depth0))
    + "\" target=\"_blank\"><i class=\"far fa-download\"></i></a>\n					<a class=\"btn btn-danger btn-sm deleteButton\" title=\"Delete\" data-gameid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"language") : depth0), depth0))
    + "\"><i class=\"far fa-trash\"></i></a>\n				</td>\n			</tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"message\">No game found.</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Games</h1>\n\n<div class=\"content\">\n	<button class=\"btn btn-primary mb-3\" id=\"uploadGameDataButton\"><i class=\"far fa-upload\"></i> Upload game data</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"games") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":1},"end":{"line":34,"column":8}}})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"dragOverlay\">\n	<div class=\"dropContainer\">\n		<div class=\"dropMessage\">\n			<i class=\"icon fas fa-file-import\"></i>\n			<div class=\"text\">Drop file to import</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});