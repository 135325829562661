import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/sessionDetails/deviceList'
import itemTemplate from 'templates/gamemaster/sessionDetails/deviceListItem'
import Backbone from 'backbone'
import Toastr from 'toastr'
import _ from 'lodash'

import Socket from 'lib/socket'
import SocketUtils from 'lib/socket/utils'
import Modal from 'lib/modal'

import DevicePickerModal from 'views/common/devicePickerModal'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:sessionDetails:deviceList')

const CONNECTED_CLASS = 'btn-success'
const DISCONNECTED_CLASS = 'btn-danger'

const DeviceItem = Marionette.View.extend({
	template: itemTemplate,
	className: 'device dropdown',

	ui: {
		mainButton: '.mainButton',
	},

	triggers: {
		'click .removeButton': 'remove',
	},

	initialize() {
		debug('Initializing DeviceItem:',this.options)
		if (this.options.isReadOnly)
			this.setDisabled()
	},

	getUuid() {
		return this.model.get('uuid')
	},

	setConnected() {
		this.ui.mainButton.removeClass(DISCONNECTED_CLASS).addClass(CONNECTED_CLASS)
	},

	setDisonnected() {
		this.ui.mainButton.removeClass(CONNECTED_CLASS).addClass(DISCONNECTED_CLASS)
	},

	async setDisabled() {
		await this.ensureRendered()
		this.ui.mainButton.attr('disabled',true)
	},
})

const DeviceListView = Marionette.CollectionView.extend({
	template: template,
	className: 'sessionDeviceList',

	childView: DeviceItem,
	childViewContainer: '.deviceContainer',

	childViewOptions() {
		return {
			isReadOnly: this.isReadOnly,
		}
	},

	childViewEvents: {
		remove: 'handleRemove',
	},

	ui: {
		addButton: '#selectDeviceButton',
		deviceButtons: '',
	},

	events: {
		'click #selectDeviceButton': 'selectDevices',
	},

	initialize() {
		debug('Initializing DeviceListView:',this.options)
		this.sessionId = this.options.sessionId
		this.isReadOnly = this.options.isReadOnly

		this.collection = new Backbone.Collection()
		this.collection.comparator = 'sortName'

		SocketUtils.registerSessionDeviceSubscriptions(this,this.sessionId,this.updateDeviceList,this.updateDeviceStates, (err)=> {
			console.error(err)
			Toastr.error(`Error listing devices: ${err}`)
		})

		if (this.isReadOnly)
			this.setReadOnly()
	},

	selectDevices() {
		const view = new DevicePickerModal()
		view.on('submit', this.handleDeviceSelection.bind(this))
		Modal.show(view)
	},

	updateDeviceStates(states) {
		debug('Showing states:',states)
		this.children.forEach((childView)=> {
			if (states.includes(childView.getUuid()))
				childView.setConnected()
			else
				childView.setDisonnected()
		})
	},

	updateDeviceList(list) {
		debug('Showing devices:',list)
		for (const item of list ?? []) {
			item.id = item.uuid
			item.sortName = item.name.toLowerCase()
		}

		this.collection.reset(list)
	},

	async handleDeviceSelection(uuids) {
		try {
			debug('Devices selected:',...uuids)
			await Socket.emit('session:device:add',this.sessionId,...uuids)
		} catch(err) {
			Toastr.error(`Error adding devices: ${err}`)
		}
	},

	async handleRemove(child) {
		console.log('>>>>>>>>>>>>>>>>>>')
		console.log('>>>>>>>>>>>>>>>>>>')
		console.log('>>>>>>>>>>>>>>>>>>')
		console.log('>>>>>>>>>>>>>>>>>>')
		try {
			debug('Removing device:',child.getUuid())
			await Socket.emit('session:device:remove',this.sessionId,child.getUuid())
		} catch(err) {
			Toastr.error(`Error removing devices: ${err}`)
		}
	},

	async setReadOnly() {
		await this.ensureRendered()
		this.isReadOnly = true
		this.ui.addButton.hide()
		this.children.forEach((childView)=> childView.setDisabled())
	},
})

export default DeviceListView
