import Marionette from 'backbone.marionette'
import template from 'templates/admin/gameCode'

import Socket from 'lib/socket'
import Toastr from 'toastr'

import Debug from 'debug'
const debug = Debug('pandore:views:admin:gameCode')

const GameCodeView = Marionette.View.extend({
	template: template,
	className: 'gamecode container',

	initialize() {
		debug('Initializing GameCodeView:',this.options)
		this.fetchVersion()
	},

	serializeData() {
		return {
			version: this.version,
		}
	},

	async fetchVersion() {
		try {
			this.version = await Socket.emit('module:code:version')
			this.render()
		} catch(err) {
			console.error('Error fetching version:',err)
			Toastr.error(`Error fetching version: ${err}`)
		}
	},
})

export default GameCodeView
