import Marionette from 'backbone.marionette'
import template from 'templates/home'

import AppMetadata from 'metadata'
import Auth from 'lib/auth'

import Debug from 'debug'
const debug = Debug('pandore:views:home')

const HomeView = Marionette.View.extend({
	template: template,
	className: 'home layout',

	regions: {
		mainRegion: '#mainRegion',
	},

	events: {
		'click #logoutButton': 'logout',
	},

	initialize() {
		debug('Initializing HomeView:',this.options)
	},

	templateContext() {
		return {
			AppMetadata: AppMetadata,
			User: Auth.getUser(),
		}
	},

	showView(view) {
		this.showChildView('mainRegion')
	},

	logout() {
		Auth.logout()
	},
})

export default HomeView
