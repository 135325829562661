import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/gameEvents'
import Toastr from 'toastr'
import Moment from 'moment'

import Socket from 'lib/socket'
import SocketUtils from 'lib/socket/utils'

import MessagingConsoleView from 'views/common/messagingConsole'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:gameEvents')

const TOKEN_SEPARATOR = ' '

const GameEventView = Marionette.View.extend({
	template: template,
	className: 'gameEvents container',

	regions: {
		messageRegion: '#messageRegion',
	},

	ui: {
		state: '.state',
		stateLabel: '.stateLabel',
	},

	initialize() {
		debug('Initializing GameEventView:',this.options)
		SocketUtils.registerGameEventMessageSubscription(this,this.handleGameEventMessage)
	},

	onRender() {
		this.messagingConsole = new MessagingConsoleView()
		this.messagingConsole.on('submit', (str)=> {
			const [target,message,sessionId,language,remainingTime] = (str||'').split(TOKEN_SEPARATOR)

			if (!target || !message) {
				Toastr.error('Missing target or message.')
				return
			}

			const data = {
				sessionId: sessionId,
				language: language,
			}

			if (remainingTime)
				data.remainingTime = parseInt(remainingTime,10)

			Socket.emit('game:event:send',target,message,data)
		})
		this.showChildView('messageRegion',this.messagingConsole)
	},

	async handleGameEventMessage(target,message,data,fromBackoffice) {
		debug('Received game event message:',target,message,data,fromBackoffice)
		await this.ensureRendered()

		const messageText = `${target} ${message} ${JSON.stringify(data)}`

		if (!fromBackoffice) {
			this.messagingConsole.addInboundMessage(messageText)
			return
		}

		const inputStr = `${target} ${message} ${data.sessionId ?? ''} ${data.language ?? ''} ${data.remainingTime ?? ''}`.trim()
		this.messagingConsole.addOutboundMessage(messageText, inputStr)
	},
})

export default GameEventView
